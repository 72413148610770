.app-info {
  background-color: #333;
  color: #fff;
  padding: 80px 0;
  text-align: center;
}

.app-info h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.app-info p {
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 80%;
  margin: 0 auto;
}

.app-info .rounded {
  border-radius: 30px !important;
}

.calendar-icon {
  color: #f0ad4e; /* Màu cam */
}
