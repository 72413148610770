.contact-info {
  background-color: #222;
  color: #fff;
  padding: 80px 0;
  text-align: center;
}

.contact-info h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.contact-info p {
  font-size: 1.2rem;
  color: #6c757d; /* Màu chữ secondary */
  margin-bottom: 40px;
}

.contact-info ul {
  list-style: none;
  padding: 0;
}

.contact-info ul li {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.contact-info ul li strong {
  font-weight: 600;
}

.contact-info ul li a {
  color: #007bff; /* Màu chữ primary */
  text-decoration: none;
}

.contact-info ul li a:hover {
  text-decoration: underline;
}
