.contact-form {
  background-color: #333;
  color: #fff;
  padding: 80px 0;
  text-align: center;
}

.contact-form h3 {
  font-size: 2.5rem;
}

.contact-form .form-control {
  background-color: #555;
  color: #fff;
  border-color: #555;
  margin-bottom: 10px;
}

.contact-form .form-control:focus {
  background-color: #777;
  color: #fff;
  border-color: #777;
}

.contact-form textarea {
  resize: none;
}

.support-info {
  font-size: 0.9rem;
  margin-top: 20px;
  color: #6c757d;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog {
  background-color: #333;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  color: #fff;
}

.dialog h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.dialog p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.dialog button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.dialog button:hover {
  background-color: #0056b3;
}
