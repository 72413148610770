html {
  scroll-behavior: smooth;
}

body {
  background-color: #111;
  color: #fff;
}

.container {
  max-width: 1200px;
}

.btn-primary {
  background-color: #f0ad4e; /* màu cam */
  border-color: #f0ad4e;
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: #ec971f; /* màu cam nhạt */
  border-color: #ec971f;
}

.form-control {
  background-color: #333;
  color: #fff;
  border-color: #555;
}

.form-control:focus {
  background-color: #555;
  color: #fff;
  border-color: #777;
}
