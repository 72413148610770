.banner {
  background-color: #222;
  color: #fff;
  padding: 100px 0;
  text-align: center;
}

.banner h1 {
  font-size: 3rem;
}

.banner p {
  font-size: 1.5rem;
}

.banner img.rounded {
  border-radius: 30px !important;
  box-shadow: 0px 0px 30px black !important;
}
